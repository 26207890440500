import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { Carousel , ScrollingCarousel } from '@trendyol-js/react-carousel';
import Gallery from 'react-grid-gallery';

const IMAGES =
[

  {
    src: "/img/charity_work/drintk_clean-charity_work (3).jpg",
    thumbnail: "/img/charity_work/drintk_clean-charity_work (3).jpg",
    // thumbnailWidth: 520,
    // thumbnailHeight: 320,
  },
  {
    src: "/img/charity_work/Harry2-650x530.jpg",
    thumbnail: "/img/charity_work/Harry2-650x530.jpg",
    // thumbnailWidth: 320,
    // thumbnailHeight: 320,
  },
  {
    src: "/img/charity_work/sch-support (4).jpg",
    thumbnail: "/img/charity_work/sch-support (4).jpg",
    // thumbnailWidth: 320,
    // thumbnailHeight: 320,
  },
  {
    src: "/img/charity_work/Harry1-650x530.jpg",
    thumbnail: "/img/charity_work/Harry1-650x530.jpg",
    // thumbnailWidth: 320,
    // thumbnailHeight: 320,
  },
  {
    src: "/img/charity_work/school-supplies (1).jpg",
    thumbnail: "/img/charity_work/school-supplies (1).jpg",
    // thumbnailWidth: 320,
    // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/drintk_clean-charity_work (4).jpg",
        thumbnail: "/img/charity_work/drintk_clean-charity_work (4).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
    src: "/img/charity_work/school-supplies (4).jpg",
    thumbnail: "/img/charity_work/school-supplies (4).jpg",
    // thumbnailWidth: 320,
    // thumbnailHeight: 320,
},
  {
    src: "/img/charity_work/school-support.jpg",
    thumbnail: "/img/charity_work/school-support.jpg",
    // thumbnailWidth: 470,
    // thumbnailHeight: 320,
},
  {
        src: "/img/charity_work/drintk_clean-charity_work (5).jpg",
        thumbnail: "/img/charity_work/drintk_clean-charity_work (5).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/drintk_clean-charity_work (6).jpg",
        thumbnail: "/img/charity_work/drintk_clean-charity_work (6).jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 320,
  },
  {
    src: "/img/charity_work/drintk_clean-charity_work (1).jpg",
    thumbnail: "/img/charity_work/drintk_clean-charity_work (1).jpg",
    // thumbnailWidth: 300,
    // thumbnailHeight: 450,
},

  {
        src: "/img/charity_work/school-supplies (1).jpeg",
        thumbnail: "/img/charity_work/school-supplies (1).jpeg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/school-supplies (2).jpg",
        thumbnail: "/img/charity_work/school-supplies (2).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/school-supplies (3).jpg",
        thumbnail: "/img/charity_work/school-supplies (3).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/school-supplies (5).jpg",
        thumbnail: "/img/charity_work/school-supplies (5).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/school-supplies (6).jpg",
        thumbnail: "/img/charity_work/school-supplies (6).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },

  {
        src: "/img/charity_work/sch-support (1).jpg",
        thumbnail: "/img/charity_work/sch-support (1).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/sch-support (2).jpg",
        thumbnail: "/img/charity_work/sch-support (2).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/sch-support (3).jpg",
        thumbnail: "/img/charity_work/sch-support (3).jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
  {
        src: "/img/charity_work/schh.jpg",
        thumbnail: "/img/charity_work/schh.jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 320,
  },
]

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  border-radius: 20px;
  border: none;
  background-color: #00FFAD;
  padding: 14px;
  font-weight: bold;
  color: #192440;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 20%;
  border: none;
  background-color: #00FFAD;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLink = styled.a`
  color: #00FFAD;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click MINT to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const revealShit = ()=>{
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * mintAmount);

    blockchain.smartContract.methods
    .reveal()
    .send({
      gasLimit: String(totalGasLimit),
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
      value: totalCostWei,
    })
    .once("error", (err) => {
      console.log(err);
      setFeedback("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
    })
    .then((receipt) => {
      console.log(receipt);
      setFeedback(
        `WOW, You Did it. You've made an IMPACT. the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
      );
      setClaimingNft(false);
      dispatch(fetchData(blockchain.account));
    });
  }

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  //Get the button
  var mybutton = document.getElementById("myBtn");
    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton ? mybutton.style.display = "block" : '';
      } else {
        mybutton ? mybutton.style.display = "none": '';
      }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function() {scrollFunction()};

  return (
    <div>
      <a href="#banner" id="myBtn" title="Go to top">Top</a>
      {/* <button onclick="topFunction()" id="myBtn" title="Go to top">Top</button> */}
        <div className="jumbotron jumbotron-fluid" id="banner" style={{backgroundImage: 'url(../img/banner-bk.jpg)', backgroundPosition: 'center'}}>
          <div className="container text-center text-md-left">
            <header>
              <div className="row justify-content-between">
                <div className="col-2">
                  <a href="/"> <img src="img/logo.png" alt="logo" /></a>

                </div>
                <div className="col-6 align-self-center text-right">
                  <nav class="nav justify-content-end">
                    <a class="text-white lead nav-link active" href="/">Home</a>
                    <a class="nav-link text-white lead" href="#Details">Details</a>
                    <a class="nav-link text-white lead" href="#story">The Story</a>
                    <a class="nav-link text-white lead" href="#roadmap">RoadMap</a>
                  </nav>
                </div>
              </div>
            </header>
            <div className="neons">

              <h1 style={{textAlign: "center"}} data-aos="fade" data-aos-easing="linear" data-aos-duration={1000} data-aos-once="true" className="display-3 text-white font-weight-bold my-5">
              <em>Narcos Mugshots Collection</em>
            </h1>
            </div>
            {(data.paused == true) ? (
              <>
              <s.FeedBackDesc2
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                MINTING ON 1ST MAY
              </s.FeedBackDesc2>
              <div id="container">
                  <div id="left">
                    <a href="https://discord.gg/fC9Cp5CM" className="d-inline-block text-center ml-2">
                    <i class="large material-icons">discord</i>
                    </a>
                  </div>
                  <div id="middle">Random Content</div>
                  <div id="right">Right Side Menu</div>
              </div>

              </>

            ) : (<>



<s.FeedBackDesc2
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                MINTING SOON !!
              </s.FeedBackDesc2>




{/*
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                </div>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                  }}
                >
                  <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                    {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                  </StyledLink>
                </s.TextDescription>
                <s.SpacerSmall />

                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                  <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      The sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                      {CONFIG.MARKETPLACE}
                    </StyledLink>
                  </>
                ) : (
                  <>
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Connect to the {CONFIG.NETWORK.NAME} network
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          CONNECT WALLET
                        </StyledButton>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <>
                        <s.FeedBackDesc
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {feedback}
                        </s.FeedBackDesc>
                        <s.SpacerMedium />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledRoundButton
                            style={{ lineHeight: 0.4 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementMintAmount();
                            }}
                          >
                            -
                          </StyledRoundButton>
                          <s.SpacerMedium />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {mintAmount}
                          </s.TextDescription>
                          <s.SpacerMedium />
                          <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementMintAmount();
                            }}
                          >
                            +
                          </StyledRoundButton>
                        </s.Container>
                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? "BUSY" : `MINT @ ${CONFIG.DISPLAY_COST}${CONFIG.NETWORK.SYMBOL}`}
                          </StyledButton>
                        </s.Container>
                      </>
                    )}
                  </>
                )} */}

            </>)}
          <p style={{textAlign: 'center', fontSize: '25px'}} data-aos="fade" data-aos-easing="linear" data-aos-duration={1000} data-aos-once="true" className="lead text-white my-4">
          When you buy a Narcos Mugshot, you’re not just buying a unique piece of art—you just did your bit in supplying clean drinking water to a remote village in Africa & Asia, you also just helped a poor child remain in school for another day, and funded a school with books and other supplies.
          </p>
          <p style={{textAlign: 'center', fontSize: '25px'}} data-aos="fade" data-aos-easing="linear" data-aos-duration={1000} data-aos-once="true" className="lead text-white my-4">
          This is the humanity collection.          </p>

          <div id="container" style={{marginTop: "50px"}}>
              <div id="left">
                <a href="https://discord.gg/fC9Cp5CM" className="d-inline-block text-center ml-2">
                  <img src="img/discord.png" width="150px"/>
                </a>
              </div>
              <div id="middle">
                <a href="https://twitter.com/MugshotsNarco" className="d-inline-block text-center ml-2">
                  <img src="img/twitter.png" width="150px"/>
                </a>
              </div>
              <div id="middle2">
                <a href="https://opensea.io/collection/narcos-mugshots" className="d-inline-block text-center ml-2">
                  <img src="img/Logomark-White.png" width="150px"/>
                </a>
              </div>
              <div id="right">
                <a href="https://discord.gg/fC9Cp5CM" className="d-inline-block text-center ml-2">
                  <img src="img/instagram.png" width="150px"/>
                </a>
              </div>
          </div>
        </div>
        </div>
        <div id="details" className="container my-5 py-2">
          <h2 className="text-center font-weight-bold my-5">The Collection</h2>
          <div className="row">
            <div data-aos="fade-up" data-aos-delay={200} data-aos-duration={1000} data-aos-once="true" className="col-md-12 text-center">
              <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>
              The Narcos Mugshots Collection (NMC) is based of the actual mugshots of notorious/controversial figures of history, not to glorify or epitomize their character but to learn and help others off their mischief. Some of these character where charity figures, and non more charitable than the most notorious of them all: Pablo Escobar.              </p>
              <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>
              Pablo Escobar built many houses, schools and soccer fields for the poor people of Medellín, Columbia. He was often referred as Robin hood because of all his good deeds to the locals of Medellín. His legend is still told till today after nearly 30 years of his death.
              </p>
              <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>
              Each NMC is uniquely and algorithmically generated from over 500 combined properties/variants across 11 categories such as their background, clothes, hair style, beard type, and accessories from their hats and jewelries. They are stored as ERC-721 NFT tokens on the Ethereum blockchain and the artwork hosted on IPFS. No two are exactly alike, and each one of them can be officially owned by a single person.
              </p>
              <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>
              Holders of this token ( for 6 months min) and members of the mugs discord community will be airdropped the next character NFT once it’s released. Get on minting this mugshots and become part of the mugshots squad.              </p>
            </div>
          </div>
        </div>
        <div class=" ">

        {/* <Carousel useArrowKeys={true} responsive={true} infinite={true} show={2} slide={3} swiping={true} transition={4.5}>
          <img src="img/msc (1).jpg" width="400px" />
          <img src="img/msc (2).jpg" width="400px" />
          <img src="img/msc (3).jpg" width="400px" />
          <img src="img/msc (4).jpg" width="400px" />
          <img src="img/msc (5).jpg" width="400px" />
          <img src="img/msc (6).jpg" width="400px" />
          <img src="img/msc (7).jpg" width="400px" />
          <img src="img/msc (8).jpg" width="400px" />
          <img src="img/msc (9).jpg" width="400px" />
          <img src="img/msc (10).jpg" width="400px" />
          <img src="img/msc (12).jpg" width="400px" />
          <img src="img/msc (13).jpg" width="400px" />          ...
      </Carousel> */}

        <ScrollingCarousel  useArrowKeys={true} show={2}>
          <img src="img/msc (1).jpg" width="400px" />
          <img src="img/msc (5).jpg" width="400px" />
          <img src="img/msc (14).jpg" width="400px" />
          <img src="img/msc (4).jpg" width="400px" />
          <img src="img/msc (13).jpg" width="400px" />
          <img src="img/msc (6).jpg" width="400px" />
          <img src="img/msc (7).jpg" width="400px" />

        </ScrollingCarousel >

        </div>
        <div  className="jumbotron jumbotron-fluid feature" id="feature-first">
          <div id="story" className="container my-5">
            <div className="row justify-content-between text-center text-md-left">
              <div data-aos="fade-right" data-aos-duration={1000} data-aos-once="true" className="col-md-8">
                <h2 className="font-weight-bold">The Mugshot Story</h2>
                <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>In 1976, Pablo Escobar founded the Medellín Cartel, which distributed powder cocaine, and established the first smuggling routes into the United States. Escobar's infiltration into the U.S. created exponential demand for cocaine and by the 1980s it was estimated Escobar led monthly shipments of 70 to 80 tons of cocaine into the country from Colombia. As a result, he quickly became one of the richest people in the world, but constantly battled rival cartels domestically and abroad, leading to massacres and the murders of police officers, judges, locals, and prominent politicians, making Colombia the murder capital of the world.</p>
                <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>By May of 1976, Escobar and some of his men were arrested for transporting cocaine. Apparently they found 40lbs of the stuff in a spare tyre. By then, Escobar was gaining infamy for his policy of <span style={{color: '#192440'}}>plata o plomo</span>, meaning <bold>silver or lead</bold> - 'you take the bribe or I'll kill you'.</p>
                <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>But this time Pablo was unable to bribe his arresting officers and he was taken in.  As he sat in jail, the case got handed off to several different judges but none of them got around to assembling the case. Meanwhile, his arresting officers were mysteriously killed and his case was dropped with all record of it apparently disappearing</p>
                <p className="my-4" style={{textAlign: 'justify', fontSize: '20px'}}>Escobar was smiling because he knew he would eventually get out and be a free man. <bold>And that he did!!!</bold></p>
                  <a href="#banner" className="btn my-4 font-weight-bold atlas-cta cta-blue">Mint Now</a>
              </div>
              <div data-aos="fade-left" data-aos-duration={1000} data-aos-once="true" className="col-md-4 align-self-center">
                <img src="img/Pablo_Escobar_Mug.jpg" width="70%" alt="The Mugshot Story" className="mx-auto d-block" />
                <h1 className="font-weight-bold" style={{textAlign: 'center', marginTop:'10px', fontSize:'20px'}}>Original Mugshot</h1>
              </div>
            </div>
          </div>
        </div>
         <div className="jumbotron jumbotron-fluid feature" id="feature-last">
          <div id="roadmap" className="container">
            <h2 style={{marginBottom: '50px'}} className="text-center font-weight-bold">The Roadmap</h2>
            <div style={{marginBottom: '50px'}} className="row justify-content-between text-center text-md-left">
              <div  data-aos="fade-left" data-aos-duration={1000} data-aos-once="true" className="col-md-8 flex-md-last">
                <h3 className="font-weight-bold"><span style={{textDecoration: 'underline'}}>Target 1:</span> $75,000 Donation for Clean Water Projects</h3>
                <p style={{textAlign: 'justify'}} className="my-4">
                Nearly one billion people do not have access to clean, safe water - that's the equivalent of 1 in 8 people on the planet, Think about it!
                </p>
                <p style={{textAlign: 'justify'}} className="my-4">
                In Africa’s 783 million people who are without access to clean water, 40% live in sub-Saharan Africa, and more than 320 million people lack access to safe drinking water. Poverty is a huge barrier to access to water and sanitation, and most of the world's poorest countries are in sub-Saharan Africa.
                </p>
                <p style={{textAlign: 'justify'}} className="my-4">
                Contaminated water can transmit diseases such diarrhoea, cholera, dysentery, typhoid and polio. Contaminated drinking-water is estimated to cause 502 000 diarrhoeal deaths each year.
                </p>
                <p style={{textAlign: 'justify'}} className="my-4">
                The fact that people in 2022 still die from this is a stain on humanity. Ending this pandemic is a collective effort, for this we are patterning with a couple charities already at the front-line to help solve this pandemic.
                </p>
                <Carousel show={5} slide={20} transition={0.5} responsive = {true}>
                  <img src="img/charity_work/orgs/14.png" width="200px" />
                  <img src="img/charity_work/orgs/11.png" width="200px" />
                  <img src="img/charity_work/orgs/12.png" width="200px" />
                  <img src="img/charity_work/orgs/13.png" width="200px" />
                  <img src="img/charity_work/orgs/15.png" width="200px" />

                </Carousel>
              </div>
              <div data-aos="fade-right" data-aos-duration={1000} data-aos-once="true" className="col-md-4 align-self-center flex-md-first">
                <img src="img/charity_work/drintk_clean-charity_work (2).jpg" width="70%" alt="Safe and reliable" className="mx-auto d-block" />
              </div>
            </div>
            <div style={{marginBottom: '50px'}} className="row justify-content-between text-center text-md-left">
              <div  data-aos="fade-left" data-aos-duration={1000} data-aos-once="true" className="col-md-8 flex-md-last">
                <h3 className="font-weight-bold"><span style={{textDecoration: 'underline'}}>Target 2:</span> $150,000 Donation for School Supplies</h3>
                <p style={{textAlign: 'justify'}} className="my-4">
                  Statistically the less educated a people are the higher the probability of crime and insurgence to penetrate their community. The uneducated are the most exploited people in our society. The biggest problem facing most a African and south Asian countries is child illiteracy, for us to have world rid of Insurgency, crime and poverty we must tackle child illiteracy now.
                </p>
                <p style={{textAlign: 'justify'}} className="my-4">
                Education is a right. It is also the most requested service, and key, to helping communities move towards peace and prosperity. Supporting education is a recognition of people’s enormous potential and the right to a better future.
                </p>
                <p style={{textAlign: 'justify'}} className="my-4">
                Everyone deserves an education regardless of age or gender, religion or physical capacity, ethnic group or displacement through conflict. Education is essential if people are to participate in the life of their own communities.
                </p>
                <p style={{textAlign: 'justify'}} className="my-4">
                  We have selected the below listed education focussed charity organiztions to make a serious impact in ending child illiteracy
                </p>

                <Carousel show={5} slide={20} transition={0.5} responsive = {true}>
                  <img src="img/charity_work/orgs/01.png" width="200px" />
                  <img src="img/charity_work/orgs/02.png" width="200px" />
                  <img src="img/charity_work/orgs/03.png" width="200px" />
                </Carousel>

              </div>
              <div data-aos="fade-right" data-aos-duration={1000} data-aos-once="true" className="col-md-4 align-self-center flex-md-first">
                <img src="img/charity_work/school-supplies (3).jpg" width="70%" alt="Safe and reliable" className="mx-auto d-block" />
              </div>
            </div>
            <div style={{marginBottom: '50px'}} className="row justify-content-between text-center text-md-left">
              <div  data-aos="fade-left" data-aos-duration={1000} data-aos-once="true" className="col-md-8 flex-md-last">
                <h3 className="font-weight-bold"><span style={{textDecoration: 'underline'}}>Target 3:</span> Airdrop Next Character to Holders</h3>
                <p style={{textAlign: 'justify'}} className="my-4">
                This collection will not get you into any exclusive parties, clubs or offer premium tickets to anything, and no apartment in any METAVERSE
                this is the humanity collection,
                rather when you buy a Narcos Mugshot, you’re not just buying a unique piece of art—you just did your bit in supplying clean drinking water to a remote village in Africa & Asia, you also just helped a poor child remain in school for another day, and funded a school with books and other supplies.
                </p>
                <p style={{textAlign: 'justify'}} className="my-4">
                Holders of more than 1 of this token ( for 6 months min) while also being a member of the mugs discord community will be airdropped the next character NFT once it’s released. Get on minting this mugshots and become part of the mugshots squad.
                </p>
                <Carousel show={5}  transition={0.5} responsive = {true}>
                  <img src="img/next/1.jpg" width="300px" />
                  <img src="img/next/2.jpg" width="300px" />
                  <img src="img/next/3.jpg" width="300px" />
                  <img src="img/next/5.jpg" width="300px" />
                  <img src="img/next/4.jpg" width="300px" />
                </Carousel>
              </div>
              <div data-aos="fade-right" data-aos-duration={1000} data-aos-once="true" className="col-md-4 align-self-center flex-md-first">
                <img src="img/next/mugs.gif" width="70%" alt="Safe and reliable" className="mx-auto d-block" />
              </div>
            </div>
          </div>
        </div>
        <div style={{
            display: "block",
            minHeight: "1px",
            width: "100%",
            border: "1px solid #ddd",
            overflow: "auto"}}>
                <Gallery
            images={IMAGES}
            enableLightbox={false}
            enableImageSelection={false}/>
        </div>
        <div className="jumbotron jumbotron-fluid" id="copyright">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-4 text-white align-self-center text-center text-md-left my-2">
                Copyright © 2022 mugshot labs.
              </div>
              <div className="col-md-4 text-white align-self-center text-center text-md-left my-2">
              Outlets: <a href="https://raritysniper.com/">NFT Rarity</a>
              </div>
              <div className="col-md-4 align-self-center text-center text-md-right my-2" id="social-media">
                <a href="http://narcosmugshots.com/" className="d-inline-block text-center ml-2">
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
                <a href="https://twitter.com/MugshotsNarco" className="d-inline-block text-center ml-2">
                  {/* <i className="fab fa-twitter" aria-hidden="true" /> */}
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
                <a href="https://discord.gg/fC9Cp5CM" className="d-inline-block text-center ml-2">
                  {/* <i className="fab fa-twitter" aria-hidden="true" /> */}
                  <i class="large material-icons">discord</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
